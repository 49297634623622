html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
span,
dt,
p,
section,
div,
header,
ul,
li,
a,
button {
  margin: 0;
  padding: 0;
}

html,
body {
  background: $black;
}

li {
  list-style: none;
}

img {
  display: block;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

button {
  background: transparent;
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }
}