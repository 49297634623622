.technicalSkills {
  &-grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 16px;
  }
}

.technicalSkill {
  opacity: 0;
  visibility: hidden;
  background-color: $smoothBlack;

  @media screen and (min-width: $xlWidth) {
    background-color: transparent;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  &-content {
    background-color: transparent;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media screen and (min-width: $xlWidth) {
      background-color: $smoothBlack;
    }
  }

  &-image {
    margin: 0 auto;
    max-width: 220px;
  }

  .section-subtitle {
    text-align: center;
  }

  .section-text {
    text-align: center;
  }
}
