.introduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 264px;
  gap: 40px;  
  margin-top: 38px;

  @media screen and (min-width: $smWidth) {
    margin-top: 50px;
  }

  @media screen and (min-width: $mdWidth) {
    margin-top: 80px;
  }

  @media screen and (min-width: $lgWidth) {
    gap: 60px;
    flex-direction: row;
  }

  @media screen and (min-width: $xlWidth) {
    gap: 74px;
    margin-top: 100px;
  }

  @media screen and (min-width: $xxlWidth) {
    margin-top: 120px;
  }

  &-header {
    .subtitle {
      margin-top: 8px;
    }
  }

  &-description {
    margin-top: 28px;

    :last-child {
      margin-top: 10px;

      @media screen and (min-width: $xlWidth) {
        margin-top: 12px;
      }
    }
  }

  img {
    max-width: 100%;
    width: 220px;

    @media screen and (min-width: $mdWidth) {
      width: 240px;
    }
    
    @media screen and (min-width: $lgWidth) {
      max-width: unset;
    }
  }
}
