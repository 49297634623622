.portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  @media screen and (min-width: $mdWidth) {
    gap: 40px;
  }

  @media screen and (min-width: $lgWidth) {
    gap: 70px;
    flex-direction: row;
  }

  @media screen and (min-width: $xlWidth) {
    gap: 80px;
  }

  &-content {
    flex: 1;
    order: 2;

    @media screen and (min-width: $lgWidth) {
      flex: 2;
      order: 1;
    }

    .section-text {
      text-align: center;

      @media screen and (min-width: $lgWidth) {
        text-align: unset;
      }
    }
  }

  &-description {
    margin-top: 20px;

    :last-child {
      margin-top: 10px;
    }
  }

  &-image {
    flex: 1;
    order: 1;

    @media screen and (min-width: $lgWidth) {
      order: 2;
    }

    img {
      margin: 0 auto;
      max-width: 100%;
      width: 300px;

      @media screen and (min-width: $mdWidth) {
        width: 320px;
      }

      @media screen and (min-width: $lgWidth) {
        width: 340px;
        max-width: unset;
      }
    }
  }
}
