.link {
  @include font('Oxygen', inherit, 400, $darkWhite);
  @include transition(all, 0.35s, cubic-bezier(0.215, 0.61, 0.355, 1), 0);

  &.primary {
    background-color: transparent;
    color: $darkCoral;

    &:hover {
      color: $orange;

      circle {
        fill: $orange;
      }
    }

    &.active {
      color: $orange;
    }

    circle {
      @include transition(fill, 0.5s, cubic-bezier(0.215, 0.61, 0.355, 1), 0);
    }
  }

  &.secondary{
    background-color: $darkCoral;
    color: $white;
    padding: 8px 10px;
    border-radius: 4px;

    &:hover {
      background-color: $orange;
    }
    
    &.active {
      background-color: $orange;
    }
  }
}