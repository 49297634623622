button {
  cursor: pointer;
  @include font('Oxygen', inherit, 400, $smoothGrey);
  @include transition(all, 0.35s, cubic-bezier(0.215, 0.61, 0.355, 1), 0);

  &.primary{
    background-color: transparent;
    color: $darkCoral;

    &:hover {
      color: $orange;

      path {
        fill: $orange;
      }
    }

    &.active {
      color: $orange;

      &.activeBg {
        background-color: $smoothBlack;
      }
    }

    path {
      fill: $darkCoral;
    }
  }
}
