.connect {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (min-width: $lgWidth) {
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
  }

  @media screen and (min-width: $xlWidth) {
    align-items: center;
    gap: 60px;
  }

  &-content {
    order: 2;
    flex: 1;

    @media screen and (min-width: $lgWidth) {
      order: 1;
    }
  }

  &-copy {
    margin-top: 20px;

    .section-text {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;

      @media screen and (min-width: $lgWidth) {
        justify-content: flex-start;
      }
    }

    button {
      display: flex;
      gap: 4px;
      margin: 5px 6px 0 0;

      @media screen and (min-width: $mdWidth) {
        margin: 6px 6px 0 0;
      }

      svg {
        margin-left: 6px;
        scale: 0.8;
      }
    }
  }

  &-links {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 30px;
    margin-top: 24px;

    @media screen and (min-width: $mdWidth) {
      margin-top: 20px;
    }

    @media screen and (min-width: $lgWidth) {
      display: grid;
      grid-template-columns:  repeat(3, 1fr);
      grid-gap: 18px;
    }

    @media screen and (min-width: $xlWidth) {
      grid-gap: 14px;
      margin-top: 16px;
    }

    .link {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-self: center;
      gap: 8px;
      @include transition(all, 0.5s, cubic-bezier(0.215, 0.61, 0.355, 1), 0);
      min-width: 180px;

      @media screen and (min-width: $mdWidth) {
        min-width: unset;
      }

      @media screen and (min-width: $lgWidth) {
        min-width: unset;
      }

      @media screen and (min-width: $xlWidth) {
        flex-direction: row;
        justify-self: unset;
      }

      svg {
        width: 34px;
        height: 34px;
      }
    }
  }

  &-image {
    display: flex;
    justify-content: center;
    order: 1;

    @media screen and (min-width: $lgWidth) {
      justify-content: flex-end;
      order: 2;
    }

    svg {
      width: 280px;
      height: 226px;

      @media screen and (min-width: $smWidth) {
        width: 300px;
        height: 243px;
      }
    }
  }
}
