
footer {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 24px;
  border-top: 1px solid $smoothBlack;

  @media screen and (min-width: $xxlWidth) {
    border-top: unset;
    overflow: hidden;
    padding: unset;
  }

  button {
    position: relative;
  }

  .shape {
    border-bottom: solid 80px $smoothBlack;
    border-left: solid 360px transparent;
    border-right: solid 360px transparent;
  }

  .content {
    text-align: center;
    font-size: 0.75em;
    text-transform: uppercase;

    @media screen and (min-width: $lgWidth) {
      font-size: 0.8rem;
    }

    @media screen and (min-width: $xxlWidth) {
      font-size: 0.7rem;
      position: absolute;
      left: calc(50% - 92px);
      top: 30px;
    }

    p {
      letter-spacing: 0.1rem;

      &:first-of-type {
        color: $darkWhite;
      }

      &:last-of-type {
        color: $orange;
        margin-top: 4px;

        @media screen and (min-width: $xxlWidth) {
          margin-top: 1px;
        }
      }
    }
  }
}
