.highlight {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  opacity: 0;
  visibility: hidden;

  @media screen and (min-width: $lgWidth) {
    flex-direction: row;
    gap: 80px;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  &-content {
    text-align: center;

    @media screen and (min-width: $lgWidth) {
      text-align: unset;
    }
  }

  &-header {
    .subtitle {
      margin-top: 8px;
    }
  }

  &-description {
    margin-top: 20px;

    :last-child {
      margin-top: 10px;
    }
  }

  &-image {
    svg{
      width: 280px;
      height: 224px;

      @media screen and (min-width: $smWidth) {
        width: 320px;
        height: 256px;
      }
    }
  }
}
