.talentsPercent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  @media screen and (min-width: $lgWidth) {
    flex-direction: row;
  }

  @media screen and (min-width: $xlWidth) {
    justify-content: space-between;
    align-items: center;
  }

  &-content {
    width: 100%;

    @media screen and (min-width: $lgWidth) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &:last-of-type {
      @media screen and (min-width: $lgWidth) {
        padding: unset;
      }

      .section-title {
        @media screen and (min-width: $lgWidth) {
          text-align: right;
        }
      }

      .talentsPercent-description {
        @media screen and (min-width: $lgWidth) {
          margin-right: 14px;
          margin-left: unset;
          align-items: flex-end;
        }

        &::before {
          left: -0.5em;
          @media screen and (min-width: $lgWidth) {
            display: block;
            right: -12px;
            left: initial;
            top: 0;
          }
        }
      }
    }
  }

  &-description {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 24px;

    @media screen and (min-width: $lgWidth) {
      margin-left: 14px;
    }

    &::before {
      display: none;
      background: $darkGrey;
      @media screen and (min-width: $lgWidth) {
        display: block;
        content: '';
        height: 100%;
        width: 1px;
        position: absolute;
        top: 0;
        left: -12px;
      }
    }
  }

  &-graphic{
    @media screen and (min-width: $xlWidth) {
      width: 340px;
      height: 340px;
    }
  }

  .recharts {
    &-wrapper { 
      max-width: 100%;
      margin: 0 auto;

      svg {
        max-width: 100%;
      }
    }

    &-pie {
      :nth-child(2) {
        transform: translate(30px,12px) scale(0.9);
      }
    }
  }
}


