.pageHeader {
  background: $black;
  border-bottom: 1px solid $smoothBlack;
  visibility: hidden;
  opacity: 0;

  @media screen and (min-width: $xxlWidth) {
    border-bottom: unset;
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  &-content{
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    min-height: 80px;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 1020px;
  }

  &-logoWrapper {
    @media screen and (min-width: $xxlWidth) {
      position: relative;
    }
  
    a {
      -webkit-tap-highlight-color: transparent;

      @media screen and (min-width: $xxlWidth) {
        position: absolute;
        z-index: 1;
        left: 36px;
        top: -32px;
      }
    }
  
    h1 {
      line-height: 0;
  
      svg{
        transform: scale(0.8);

        @media screen and (min-width: $xxlWidth) {
          transform: scale(0.9);
        }
      }
  
      path {
        @include transition(all, 0.5s, cubic-bezier(0.215, 0.61, 0.355, 1), 0);
      }
    }
  }

  &-shape {
    position: absolute;
    left: -284px;
    top: -988px;
    width: 1040px;
    height: 820px;
    background: $smoothBlack;
    transform: rotate(-111deg);
  }

  &-navigation {
    width: 100%;
    height: 100vh;

    @media screen and (min-width: $mdWidth) {
      height: unset;
      width: unset;
    }
  }

  &-menu {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin-top: 120px;

    @media (orientation: landscape) and (max-width: $mdWidth) {
      margin-top: 0;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 70px;
    }

    @media screen and (min-width: $mdWidth) {
      gap: 34px;
      margin-top: unset;
      flex-direction: row;
      justify-content: space-between;
      width: unset;
    }
    
    @media screen and (min-width: $mdWidth) {
      gap: 34px;
      margin-top: unset;
      flex-direction: row;
      justify-content: space-between;
      width: unset;
    }

    &-item {
      .routerLink {
        font-size: 1.8rem;

        @media screen and (min-width: $mdWidth) {
          font-size: 1.2rem;
        }

        @media screen and (min-width: $xxlWidth) {
          font-size: 1.3rem;
        }
      }
  
      svg {
        transform: scale(1.5);
  
        @media screen and (min-width: $mdWidth) {
          transform: unset;
        }
      }
    }
  }

  &-mobileMenuButton{
    position: absolute;
    right: 30px;
    top: 30px;
    width: 30px;
    height: 30px;
    outline: none;

    @media screen and (min-width: $mdWidth) {
      display: none;
    }

    span {
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      background: $white;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      @include transition(all, 0.5s, cubic-bezier(0.215, 0.61, 0.355, 1), 0);

      &:nth-of-type(1) {
        top: 0;
      }

      &:nth-of-type(2),
      &:nth-of-type(3) {
        top: 9px;
      }

      &:nth-of-type(4) {
        top: 18px;
      }
    }
    
    &.open {
      span {
        &:nth-of-type(1),
        &:nth-of-type(4) {
          top: 10px;
          width: 0;
          left: 50%;
        }

        &:nth-of-type(2) {
          transform: rotate(45deg);
        }

        &:nth-of-type(3) {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
