html {
  @include font('Oxygen', 17px, 400, $smoothGrey);
}

body {
  overflow-x: hidden;
  line-height: 1;

  &.masked {
    overflow: hidden;
  }
}

main {
  opacity: 0;
  visibility: hidden;
  margin: 0 auto;
  max-width: 1000px;
  padding: 32px;
  @include transition(opacity, 0.5s, ease-out, 0.3s);

  @media screen and (min-width: $smWidth) {
    padding: 60px;
  }

  @media screen and (min-width: $mdWidth) {
    padding: 7vh 80px 80px;
  }

  @media screen and (min-width: $lgWidth) {
    padding: 12vh 70px 100px;
  }

  @media screen and (min-width: $xlWidth) {
    padding: 16vh 0 100px;
  }

  @media screen and (min-width: $xxlWidth) {
    padding: 18vh 0 100px;
  }

  &.about {
    @media screen and (min-width: $xlWidth) {
      padding: 12vh 0 100px;
    }

    @media screen and (min-width: $xxlWidth) {
      padding: 17vh 0 100px;
    }
  }
  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

section {
  opacity: 0;
  visibility: hidden;
  margin-top: 80px;

  @media screen and (min-width: $xlWidth) {
    margin-top: 100px;
  }

  @media screen and (min-width: $xxlWidth) {
    margin-top: 120px;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

.title {
  @include font('Oswald', 2.3rem, 700, $color: $lightWhite);
  text-transform: uppercase;
  text-align: center;

  @media screen and (min-width: $lgWidth) {
    text-align: unset;
  }
}

.subtitle {
  @include font('Oxygen', 1.3rem, 400, $color: $darkWhite);
  text-align: center;
  line-height: 1.3;

  @media screen and (min-width: $lgWidth) {
    text-align: unset;
  }
}

.section{
  &-title {
    @include font('Oswald', 1.5rem, 700, $color: $lightWhite);
    text-transform: uppercase;
    text-align: center;

    @media screen and (min-width: $lgWidth) {
      font-size: 1.6rem;
      text-align: unset;
    }

    @media screen and (min-width: $xlWidth) {
      font-size: 1.7rem;
    }
  }

  &-subtitle {
    @include font('Oswald', 1.3rem, 700, $color: $smoothGrey);
    text-transform: uppercase;
    text-align: center;
    line-height: 1.2;

    @media screen and (min-width: $mdWidth) {
      font-size: 1.4rem;
    }

    @media screen and (min-width: $lgWidth) {
      text-align: unset;
    }
  }

  &-text {
    @include font('Oxygen', 1.1rem, 300, $color: $smoothGrey);
    line-height: 1.5;
    text-align: center;

    @media screen and (min-width: $mdWidth) {
      font-size: 0.98rem;
    }

    @media screen and (min-width: $lgWidth) {
      text-align: unset;
    }

    @media screen and (min-width: $xlWidth) {
      font-size: 1rem;
    }
  }
}