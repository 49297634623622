// colors
$darkCoral: #d35d4e ;
$orange: #ef5000;
$lightWhite: #ffffff;
$white: #f3f3f3;
$darkWhite: #e5e4e9;
$smoothGrey: #d4d4d4;
$lightGrey: #abaaaa;
$mediumGrey: #707070;
$grey: #747474;
$darkGrey: #545353;
$smoothBlack: #32302f;
$black: #221f1e;

// breakpoints
$smWidth: 480px;
$mdWidth: 768px;
$lgWidth: 992px;
$xlWidth: 1200px;
$xxlWidth: 1650px;
