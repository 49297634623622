.shapesBg {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  overflow: hidden;
  width: 100%;
  display: none;

  @media screen and (min-width: $xxlWidth) {
    display: block;
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  &-firstLeftShape {
    position: fixed;
    z-index:1;
    width: 100%;
    height: 38%;
    background: $orange;
  }

  &-secondLeftShape {
    position: fixed;
    width: 100%;
    height: 37%;
    background: $smoothBlack;
  }

  &-firstRightShape {
    position: fixed;
    width: 100%;
    height: 35%;
    background: $smoothBlack;
  }
  
  &-secondRightShape {
    position: fixed;
    width: 100%;
    height: 35%;
    background: $darkGrey;
  }
}

