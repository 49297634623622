@mixin font($family, $size, $weight, $color: $darkGrey) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

@mixin transition($property, $duration, $easing, $delay) {
  -webkit-transition-property: $property;
  -webkit-transition-duration: $duration;
  -webkit-transition-timing-function: $easing;
  -webkit-transition-delay: $delay;

  -khtml-transition-property: $property;
  -khtml-transition-duration: $duration;
  -khtml-transition-timing-function: $easing;
  -khtml-transition-delay: $delay;

  -moz-transition-property: $property;
  -moz-transition-duration: $duration;
  -moz-transition-timing-function: $easing;
  -moz-transition-delay: $delay;

  -ms-transition-property: $property;
  -ms-transition-duration: $duration;
  -ms-transition-timing-function: $easing;
  -ms-transition-delay: $delay;

  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $easing;
  transition-delay: $delay;
}
