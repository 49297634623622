.pageError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media screen and (min-width: $lgWidth) {
    flex-direction: row;
    gap: 80px;
  }
  
  &-image{
    svg {
      width: 300px;
      height: 198px;

      @media screen and (min-width: $mdWidth) {
        width: 320px;
        height: 212px;
      }

      @media screen and (min-width: $xlWidth) {
        width: 360px;
        height: 238px;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  + footer {
    display: none;
  }
}