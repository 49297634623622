.introShapes {
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: $black;

  &-firstLeftShape {
    position: absolute;
    width: 150%;
    height: 100%;
    background: $orange;
    transform: translateX(-2%) translateY(-120%);
  }

  &-secondLeftShape {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $smoothBlack;
    transform: translateX(100%) translateY(-50%);
  }

  &-firstRightShape {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: $smoothBlack;
    transform: translateX(-100%) translateY(50%);
  }
  
  &-secondRightShape {
    position: absolute;
    width: 150%;
    height: 100%;
    background: $darkGrey;
    transform: translateY(200%);
  }
}
