.sideProject {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  @media screen and (min-width: $mdWidth) {
    gap: 40px;
  }

  @media screen and (min-width: $lgWidth) {
    gap: 70px;
    flex-direction: row;
  }

  @media screen and (min-width: $xlWidth) {
    gap: 80px;
  }

  &:nth-of-type(even) {
    @media screen and (min-width: $lgWidth) {
      .sideProject-contentWrapper {
        order: 2;
      }

      .sideProject-image {
        order: 1;
      }
    }
  }

  &-contentWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 2;
    
    @media screen and (min-width: $lgWidth) {
      align-items: flex-start;
      order: 1;
    }

    .section-title {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        transform: scale(0.8);
      }
    }
  }

  &-description {
    margin-top: 16px;

    :last-child {
      margin-top: 10px;

      @media screen and (min-width: $xlWidth) {
        margin-top: 12px;
      }
    }
  }

  &-actions{
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 20px;

    @media screen and (min-width: $lgWidth) {
      justify-content: flex-start;
    }
  }

  &-image {
    order: 1;

    @media screen and (min-width: $lgWidth) {
      order: 2;
      width: 380px;
      height: 298px;
    }

    @media screen and (min-width: $xlWidth) {
      width: 420px;
      height: 329px;
    }

    img {
      max-width: 100%;
      width: 300px;

      @media screen and (min-width: $smWidth) {
        width: 330px;
      }
  
      @media screen and (min-width: $mdWidth) {
        max-width: unset;
        width: 380px;
      }
  
      @media screen and (min-width: $xlWidth) {
        width: 420px;
      }
    }
  }
}
