.softSkills {
  &-chart {
    margin-top: 24px;
    @media screen and (min-width: $xlWidth) {
      height: 392px;
    }
  }

  &-bubbles {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;

    @media screen and (min-width: $lgWidth) {
      justify-content: space-between;
    }

    li{
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 50%;
      padding: 24px;
      height: 150px;
      width: 150px;
      display: flex;
      justify-content: center;
      gap: 4px;
      background-color: $smoothBlack;

      span {
        font-weight: 400;
        font-size: 1.1rem;
      }

      &:nth-of-type(even) {
        background-color: $mediumGrey;

        span {
          color: $black;
        }
      }

      div {
        display: flex;
        align-items: center;
        gap: 3px;

        span {
          line-height: 1;

          &:first-of-type {
            font-size: 2.1rem;
          }

          &:last-of-type {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .recharts-wrapper {
    display: none;
    max-width: 100%;
    margin: 0 auto;
    height: fit-content !important;

    @media screen and (min-width: $lgWidth) {
      display: block;
    }
    
    @media screen and (min-width: $xlWidth) {
      transform: unset;
    }

    svg {
      max-width: 100%;
      height: fit-content;
    }

    .recharts-cartesian-grid-horizontal{
      line {
        stroke: $grey;
      }
    }

    .recharts-cartesian-grid-vertical{
      line {
        stroke: $grey;
      }
    }
  }
}