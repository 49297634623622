.routerLink {
  @include font('Oswald', 1.5rem, 700, $color: $darkCoral);
  display: flex;
  letter-spacing: 0.04rem;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
  outline: none;
  @include transition(all, 0.5s, cubic-bezier(0.215, 0.61, 0.355, 1), 0);

  @media screen and (min-width: $mdWidth) {
    gap: 8px;
    font-size: 1.2rem;
    flex-direction: row;

    &:hover {
      color: $orange;

      path {
        fill: $orange;
      }
    }
  }

  path {
    fill: $darkCoral;
    @include transition(all, 0.5s, cubic-bezier(0.215, 0.61, 0.355, 1), 0);
  }

  &.active {
    color: $smoothGrey;
    pointer-events: none;

    path {
      fill: $orange;
    }
  }
}