.about {
  .highlight {
    display: flex;
    justify-content: center;
    gap: 14px;
    opacity: 0;
    visibility: hidden;

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    &-bubbles {
      display: none;

      @media screen and (min-width: $xlWidth) {
        display: block;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      > span {
        line-height: 0.85;
      }
    }

    &-greetTxt {
      display: flex;
      gap: 10px;
      margin-bottom: 8px;

      @media screen and (min-width: $lgWidth) {
        margin-bottom: 12px;
      }

      span {
        color: $lightGrey;
        font-size: 1.43rem;
        font-weight: 400;
        display: flex;
        gap: 10px;

        @media screen and (min-width: $smWidth) {
          font-size: 1.48rem;
        }

        @media screen and (min-width: $mdWidth) {
          font-size: 2rem;
        }

        @media screen and (min-width: $lgWidth) {
          font-size: 2.39rem;
        }

        @media screen and (min-width: $xlWidth) {
          font-size: 2.5rem;
        }

        @media screen and (min-width: $xxlWidth) {
          font-size: 2.65rem;
        }

        &:nth-of-type(1) {
          font-weight: 300;
        }

        &:nth-of-type(2) {
          color: $smoothGrey;
        }
      }
    }

    &-creativeTxt {
      font-family: 'Oswald';
      font-size: 4.2rem;
      font-weight: 700;
      color: $smoothGrey;
      margin-bottom: 8px;

      @media screen and (min-width: $smWidth) {
        font-size: 6.4rem;
        margin-bottom: 12px;
      }

      @media screen and (min-width: $mdWidth) {
        font-size: 8.6rem;
      }

      @media screen and (min-width: $lgWidth) {
        font-size: 10.2rem;
      }

      @media screen and (min-width: $xlWidth) {
        font-size: 10.6rem;
      }

      @media screen and (min-width: $xxlWidth) {
        font-size: 10.7rem;
      }
    }

    &-developerTxt {
      font-family: 'Oswald';
      font-size: 3.5rem;
      color: $grey;
      font-weight: 700;
      margin-bottom: 16px;

      @media screen and (min-width: $smWidth) {
        font-size: 5.3rem;
      }

      @media screen and (min-width: $mdWidth) {
        margin-bottom: 20px;
        font-size: 7.2rem;
      }

      @media screen and (min-width: $lgWidth) {
        margin-bottom: 26px;
        font-size: 8.57rem;
      }

      @media screen and (min-width: $xlWidth) {
        font-size: 8.9rem;
      }

      @media screen and (min-width: $xxlWidth) {
        font-size: 9rem;
      }
    }

    &-welcomeTxt {
      font-size: 1.29rem;
      font-weight: 300;
      color: $lightGrey;
      text-align: right;

      @media screen and (min-width: $smWidth) {
        font-size: 1.29rem;
      }

      @media screen and (min-width: $mdWidth) {
        font-size: 1.75rem;
      }

      @media screen and (min-width: $lgWidth) {
        font-size: 2.09rem;
      }

      @media screen and (min-width: $xlWidth) {
        font-size: 2.18rem;
      }

      @media screen and (min-width: $xxlWidth) {
        font-size: 2.3rem;
      }
    }
  }
}
