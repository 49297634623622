.jobs {
  &-wrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media screen and (min-width: $lgWidth) {
      flex-direction: row;
      gap: 60px;
    }
  }

  &-sidebar {
    position: relative;

    &-list {
      display: flex;
      position: relative;
      overflow-x: auto;

      @media screen and (min-width: $lgWidth) {
        overflow-x: unset;
        justify-content: unset;
        display: block;
        gap: unset;
      }

      &::before {
        background: $darkGrey;
        content: '';
        position: absolute;
        display: none;

        @media screen and (min-width: $lgWidth) {
          display: block;
          top: 0;
          height: 100%;
          width: 1px;
        }
      }

      &::after {
        background: $orange;
        content: '';
        height: 2px;
        width: 135px;
        position: absolute;
        top: 36px;
        left: 0;
        z-index: 1;
        @include transition(transform, 0.2s, ease-out, 0);

        @media screen and (min-width: $lgWidth) {
          width: 120px;
          display: block;
          height: 41px;
          width: 1px;
          top: 0;
        }
      }

      &.active {
        &-oomnitza{
          &::after {
            transform: translateX(0);

            @media screen and (min-width: $lgWidth) {
              transform: translateY(0);
            }
          }
        }

        &-kitman{
          &::after {
            transform: translateX(137px);

            @media screen and (min-width: $lgWidth) {
              transform: translateY(45px);
            }
          }
        }

        &-daft{
          &::after {
            transform: translateX(270px);

            @media screen and (min-width: $lgWidth) {
              transform: translateY(89px);
            }
          }
        }

        &-atresmedia{
          &::after {
            transform: translateX(405px);

            @media screen and (min-width: $lgWidth) {
              transform: translateY(133px);
            }
          }
        }

        &-yondbee{
          &::after {
            transform: translateX(540px);

            @media screen and (min-width: $lgWidth) {
              transform: translateY(176px);
            }
          }
        }

        &-comunica{
          &::after {
            transform: translateX(675px);

            @media screen and (min-width: $lgWidth) {
              transform: translateY(220px);
            }
          }
        }
      }
    }

    &-horizontalLine {
      background: $darkGrey;
      height: 2px;
      width: 100%;
      position: absolute;
      top: 36px;
      left: 0;

      @media screen and (min-width: $lgWidth) {
        display: none;
      }
    }

    button {
      padding: 10px;
      line-height: 1.4;
      min-width: 135px;

      @media screen and (min-width: $lgWidth) {
        padding: 8px 12px 12px 16px;
        width: 124px;
        text-align: start;
      }
    }
  }

  &-content {
    position: relative;
    width: 100%;
    flex: 1;
  }
}

.job {
  @media screen and (min-width: $lgWidth) {
    padding: 12px;
  }

  &-period {
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    margin-top: 4px;

    @media screen and (min-width: $lgWidth) {
      margin-top: 8px;
      justify-content: flex-start;
    }
  }

  &-description {
    margin-top: 20px;

    @media screen and (min-width: $lgWidth) {
      margin-left: 18px;
    }

    :last-child {
      margin-top: 18px;

      @media screen and (min-width: $lgWidth) {
        margin-top: 12px;
      }
    }
  }

  li {
    @media screen and (min-width: $lgWidth) {
      list-style: disc;
    }
  }
}